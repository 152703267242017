export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const MY_ORDER_LIST_REQUEST = 'MY_ORDER_LIST_REQUEST';
export const MY_ORDER_LIST_SUCCESS = 'MY_ORDER_LIST_SUCCESS';
export const MY_ORDER_LIST_FAIL = 'MY_ORDER_LIST_FAIL';
export const MY_ORDER_LIST_RESET = 'MY_ORDER_LIST_RESET';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';

export const ORDER_DELIVERY_REQUEST = 'ORDER_DELIVERY_REQUEST';
export const ORDER_DELIVERY_SUCCESS = 'ORDER_DELIVERY_SUCCESS';
export const ORDER_DELIVERY_FAIL = 'ORDER_DELIVERY_FAIL';
export const ORDER_DELIVERY_RESET = 'ORDER_DELIVERY_RESET';

export const ORDER_SHIPPED_REQUEST = 'ORDER_SHIPPED_REQUEST';
export const ORDER_SHIPPED_SUCCESS = 'ORDER_SHIPPED_SUCCESS';
export const ORDER_SHIPPED_FAIL = 'ORDER_SHIPPED_FAIL';
export const ORDER_SHIPPED_RESET = 'ORDER_SHIPPED_RESET';

export const ORDER_UPDATE_TRACKING_REQUEST = 'ORDER_UPDATE_TRACKING_REQUEST';
export const ORDER_UPDATE_TRACKING_SUCCESS = 'ORDER_UPDATE_TRACKING_SUCCESS';
export const ORDER_UPDATE_TRACKING_FAIL = 'ORDER_UPDATE_TRACKING_FAIL';
export const ORDER_UPDATE_TRACKING_RESET = 'ORDER_UPDATE_TRACKING_RESET';